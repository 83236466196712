import React from 'react'
import Button from '@santiment-network/ui/Button'
import PaymentDialog from '../PaymentDialog/PaymentDialog'
import ChangePlanDialog from '../ChangePlanDialog/ChangePlanDialog'
import PipedriveDialogBtn from '../Pipedrive/Pipedrive'
import { tr, trStr } from '../../utils/translate'
import styles from './index.module.scss'

const PlanActionDialog = props =>
  props.subscription ? (
    <ChangePlanDialog {...props} />
  ) : (
    <PaymentDialog {...props} />
  )

export default {
  FREE: {
    discount: 'price.bill_discount.free',
    link: 'Upgrade now',
    Component: () => (
      <Button accent="blue" border fluid className={styles.link} disabled>
        {tr('cta.default_plan')}
      </Button>
    ),
    features: [
      'HDA',
      'metrics',
      intl => `100 ${trStr(intl, 'plan.feature.AC')}`,
      intl => `500 ${trStr(intl, 'plan.feature.AC.hour')}`,
      intl => `1K ${trStr(intl, 'plan.feature.AC.month')}`,
      'support',
    ],
  },
  ESSENTIAL: {
    link: 'Get Started Now',
    Component: PlanActionDialog,
    features: [
      'HDA',
      'metrics',
      intl => `400 ${trStr(intl, 'plan.feature.AC')}`,
      intl => `20k ${trStr(intl, 'plan.feature.AC.hour')}`,
      intl => `300K ${trStr(intl, 'plan.feature.AC.month')}`,
      'support',
    ],
  },
  PRO: {
    isPopular: true,
    Component: PlanActionDialog,
    link: 'Get Started Now',
    features: [
      'HDA',
      <span>
        {tr('plan.feature.metrics', 'Essential')} +
        <a className={styles.metricsLink} href="#faq-advanced">
          {tr('plan.feature.metrics.advanced', 'Advanced metrics*')}
        </a>
      </span>,
      intl => `600 ${trStr(intl, 'plan.feature.AC')}`,
      intl => `30k ${trStr(intl, 'plan.feature.AC.hour')}`,
      intl => `600K ${trStr(intl, 'plan.feature.AC.month')}`,
      'support',
    ],
  },
  // for deprecated plans support
  PREMIUM: {
    desc: '',
    Component: PlanActionDialog,
    link: 'Upgrade now',
    features: [
      'HDA',
      intl => (
        <>
          180 {trStr(intl, 'plan.feature.AC')}
          <br />
          500k {trStr(intl, 'plan.feature.AC.month')}
        </>
      ),
      <>
        {tr('plan.feature.AM')} <span className={styles.ast}>*</span>
      </>,
      tr('plan.feature.NA'),
    ],
  },
  CUSTOM: {
    discount: 'price.bill_discount.custom',
    link: 'cta.contact',
    Component: props => (
      <PipedriveDialogBtn
        {...props}
        title="Enterprise plan Pipedrive form"
        src="https://pipedrivewebforms.com/form/0527db4d781f7c4c0760b7bc7a58549c4144829"
      />
    ),
    features: [
      'HDA',
      <span>
        {tr('plan.feature.metrics', 'Essential')} +
        <a className={styles.metricsLink} href="#faq-advanced">
          {tr('plan.feature.metrics.advanced', 'Advanced metrics*')}
        </a>
      </span>,
      intl => `${trStr(intl, 'plan.custom.feature.CDCR.top')}`,
      intl => `${trStr(intl, 'plan.custom.feature.CDCR.bottom')}`,
      'support',
    ],
  },
}
