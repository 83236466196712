// @ts-ignore
import { query } from 'webkit/api'

const CURRENT_USER_QUERY = `
  {
    currentUser {
      username
      privacyPolicyAccepted
    }
  }
`

const currentUserAccessor = ({ currentUser }) => currentUser
export const queryCurrentUser = () =>
  query(CURRENT_USER_QUERY).then(currentUserAccessor)
