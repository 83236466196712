import React from 'react'
import Title from '../Title/Title'
import { tr } from '../../utils/translate'
import PricingTable from './PricingTable.js'
import IconArrow from '../IconArrow.js'
import styles from './index.module.scss'

const Pricing = () => (
  <section id="pricing" className={styles.pricing}>
    <Title className={styles.title}>
      <IconArrow />
      {tr('pricing.title', 'Pick Your Plan')}
    </Title>
    <PricingTable />
  </section>
)

export default Pricing
