import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { Query } from 'react-apollo'
import cx from 'classnames'
import Tabs from '@santiment-network/ui/Tabs'
// import Button from '@santiment-network/ui/Button'
import { CURRENT_USER_QUERY } from '../../gql/user'
import Features from '../Features/Features'
import PlanRestrictBtn from './PlanRestrictBtn'
import { PLANS_QUERY } from '../../gql/plans'
import PLANS from './prices'
import {
  findNeuroPlan,
  getCurrentNeuroSubscription,
  formatPrice,
  getAlternativeBillingPlan,
} from '../../utils/plans'
import { tr, trStr } from '../../utils/translate'
import styles from './index.module.scss'
import { trackInteraction } from '../../utils/events'

const billingOptions = [
  { index: 'year', content: tr('pricing.bill.year') },
  { index: 'month', content: tr('pricing.bill.month') },
]

export default injectIntl(({ intl, classes = {}, onDialogClose }) => {
  const [billing, setBilling] = React.useState('year')

  return (
    <>
      <div className={cx(styles.billing, classes.billing)}>
        <Tabs
          options={billingOptions}
          defaultSelectedIndex="year"
          onSelect={res => {
            trackInteraction({
              type: 'select_pricing_plan',
              metric: res === 'year' ? 'bill_annually' : 'bill_monthly',
            })

            setBilling(res)
          }}
          classes={{ tab: styles.tab, selectedTab: styles.selectedTab }}
          className={styles.bill}
        />
      </div>
      <Query query={CURRENT_USER_QUERY}>
        {({ data: { currentUser } = {} }) => {
          const subscription = getCurrentNeuroSubscription(currentUser)
          const userPlan = subscription && subscription.plan.id
          const isSubscriptionCanceled =
            subscription && subscription.cancelAtPeriodEnd

          return (
            <Query query={PLANS_QUERY}>
              {({ data: { productsWithPlans = [] } = {} }) => {
                const neuro = productsWithPlans.find(findNeuroPlan)
                if (!neuro) {
                  return null
                }

                const allPlans = neuro.plans.filter(
                  ({ isDeprecated, isPrivate, name }) =>
                    (!isDeprecated && !isPrivate) || name === 'FREE'
                )

                const shownPlans = allPlans.filter(({ name, interval }) => {
                  const card = PLANS[name]

                  return (
                    card &&
                    !card.notShow &&
                    (interval === billing || name === 'FREE')
                  )
                })

                return (
                  <>
                    <div className={styles.cards}>
                      {shownPlans.map(({ id, name, amount }, idx) => {
                        const card = PLANS[name]

                        const sameAsUserPlan = id === userPlan
                        let [price, priceType, displayedPrice] = formatPrice(
                          amount,
                          name,
                          billing
                        )
                        if (price === 'Custom') {
                          price = tr('plan.custom.price')
                        }
                        if (priceType) {
                          priceType = tr('billing.month.short')
                        }

                        const intlId = `plan.${name.toLowerCase()}`

                        const { amount: altAmount, interval: altInterval } =
                          getAlternativeBillingPlan(allPlans, name, billing) ||
                          {}

                        const [altPrice] = formatPrice(
                          altAmount,
                          null,
                          altInterval
                        )

                        const title = trStr(intl, intlId + '.title')

                        return (
                          <div
                            className={cx(
                              styles.card,
                              classes.card,
                              idx === 0 && styles.card_first,
                              idx === shownPlans.length - 1 && styles.card_last,
                              card.isPopular && styles.card_popular,
                              sameAsUserPlan && styles.card_active
                            )}
                            key={name}
                          >
                            <div className={styles.card__top}>
                              <h3 className={styles.card__title}>
                                {title}
                                {card.isPopular && (
                                  <span className={styles.popular}>
                                    {tr('plan.popular')}
                                  </span>
                                )}
                              </h3>
                            </div>
                            <div className={styles.desc}>
                              {tr(intlId + '.desc')}
                            </div>
                            <div className={styles.details}>
                              <div className={styles.price}>
                                <span className={styles.price__currency}>
                                  {!isNaN(displayedPrice) && '$'}
                                </span>
                                <span>
                                  {displayedPrice !== undefined
                                    ? displayedPrice
                                    : price}
                                </span>
                                {/*<span className={styles.price__type}>*/}
                                {/*  {priceType}*/}
                                {/*</span>*/}
                              </div>
                              <div className={styles.discount}>
                                {card.discount ? (
                                  tr(card.discount)
                                ) : (
                                  <>
                                    {tr('price.bill_discount.left')}
                                    {altPrice}
                                    {tr(`price.bill_discount.${altInterval}`)}
                                  </>
                                )}
                              </div>
                              {!currentUser ||
                              sameAsUserPlan ||
                              isSubscriptionCanceled ? (
                                <PlanRestrictBtn
                                  sameAsUserPlan={sameAsUserPlan}
                                  name={name}
                                  isSubscriptionCanceled={
                                    isSubscriptionCanceled
                                  }
                                />
                              ) : (
                                <card.Component
                                  currentUser={currentUser}
                                  title={title}
                                  label={card.link}
                                  price={amount}
                                  billing={billing}
                                  planId={+id}
                                  subscription={subscription}
                                  onDialogClose={onDialogClose}
                                />
                              )}
                              <Features
                                intl={intl}
                                data={card.features}
                                classes={styles}
                                intlId={`plan.${name.toLowerCase()}.feature.`}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    {/*<PricingDetailsToggle*/}
                    {/*  isLoggedIn={currentUser}*/}
                    {/*  plans={plans}*/}
                    {/*  userPlan={userPlan}*/}
                    {/*  billing={billing}*/}
                    {/*  subscription={subscription}*/}
                    {/*  onDialogClose={onDialogClose}*/}
                    {/*/>*/}
                  </>
                )
              }}
            </Query>
          )
        }}
      </Query>
    </>
  )
})
